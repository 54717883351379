<template>
	<div class="container">
		<div class="office-form">
			<h1>สมัครร้านค้ากับ Yellowtire.com</h1>

            <seller-profile v-if="isDisplayForm"></seller-profile>

			<template v-if="!isDisplayForm">
				<div class="message">
					<b-icon icon="check2-circle" class="icon"></b-icon>
					<h2>Yellowtire.com ได้รับข้อมูลลงทะเบียนสมัครร้านค้าของท่านแล้ว</h2>
					<p>ระบบกำลังดำเนินการตรวจสอบข้อมูล ทางบริษัทจะส่งอีเมลถึงท่าน เพื่อขอเอกสารการสมัครร้านค้าต่อไป</p>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import SellerProfile from '@/components/seller/shop/Profile';
import RouterPath from '@/router/path';
import SellerService from '@/services/sellerService';

export default {
    components: { SellerProfile },
	data() {
		return {
			isDisplayForm: true
		};
	},
	mounted() {
		this.checkRegistrationStatus();
	},
	methods: {
		async checkRegistrationStatus() {
			let loader = this.$modalLoader.render();
			const result = await SellerService.checkStoreRegistration();
			loader.hide();

			if (result.data?.status === -1) {
				this.$router.push(RouterPath.SELLER_REGISTER);
			} if (result.data?.status === 1) {
				this.isDisplayForm = false;
			}
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

h1 {
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 25px;
	color: #333;
	text-align: center;
}

.office-form {
	max-width: 1000px;
	margin: auto;
	padding: 40px 0 60px;

	.loading {
		height: 400px;
	}

	.message {
		text-align: center;
		padding: 50px;
		max-width: 600px;
		margin: auto;
		color: #333;
		background: $lightBackground;

		.icon {
			font-size: 150px;
			color: #555;
		}

		h2 {
			font-size: 34px;
			font-weight: bold;
			margin: 30px 0 15px;
		}

		p {
			font-size: 28px;
		}
	}
}
</style>